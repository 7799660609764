import Rellax from 'rellax';

const parallax = () => {

    document.querySelectorAll('.parallax').forEach(($el: Element) => {
        if (!$el.hasAttribute('data-rellax-percentage')) {
            $el.setAttribute('data-rellax-percentage', '0.5');
        }

        if (!$el.hasAttribute('data-rellax-speed')) {
            $el.setAttribute('data-rellax-speed', '2');
        }
    });

    const rellax = new Rellax('.parallax');
    window.onload = () => {
        rellax.refresh();
    };
};

export default parallax;
